/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
      


        $('#gform_submit_button_1').click( function() {
          $('html,body').animate({ scrollTop: $(this).offset().top - ( $(window).height() - $(this).outerHeight(true) ) / 2  }, 5000);
        });
        
        //Remove o header e descrição do formulário do header quando se clica no botão
        $("#gform_submit_button_1").click(function () {
          setTimeout(function(){ 
            $('.form-container .header').css('display','none');
            $('.form-container .sub-header').css('display','none');
           // $('#gform_confirmation_wrapper_1').scrollView();
           
        }, 2850);
      });



        //Ao clicar no botão de submit do formulário no footer o gato que está no fundo do footer irá desaparecer
        $("#gform_submit_button_2").click(function () {
          
          setTimeout(function(){ 
            $('.cat').css('display','none');
           // $('.back').remove();
        }, 850);
      });
        // JavaScript to be fired on all pages

       /* var category = $('.list-group-item').data('category');
        var current_category =$('.list-group-item').data('current-category');

        if(category === current_category){
            $('.list-group-item').addClass('active');
        }*/
        //$("<div class='background-form'>cenas</div>").insertAfter(".email-container #gform_fields_1");
        /**************
         * 
         * INICIAR O WOW
         * 
         ***************/
        var wow = new WOW(
          {
            boxClass:     'wow',      // animated element css class (default is wow)
            animateClass: 'animatedClass', // animation css class (default is animated)
            offset:       0,          // distance to the element when triggering the animation (default is 0)
            mobile:       true,       // trigger animations on mobile devices (default is true)
            live:         true,       // act on asynchronously loaded content (default is true)
            callback:     function(box) {
              // the callback is fired every time an animation is started
              // the argument that is passed in is the DOM node being animated
            },
            scrollContainer: null,    // optional scroll container selector, otherwise use window,
            resetAnimation: true,     // reset animation on end (default is true)
          }
        );
        wow.init();

        /**
         * Galeria de thumbnails com lightbox Homepage
         */
        $('[data-toggle="lightbox"]').click(function(event) {
          event.preventDefault();
        $(this).ekkoLightbox();
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        //Código para mostrar a caixa de busca na Homepage
        /*$(".search-icon").on('click',function(e){
          $( ".search-container" ).fadeIn( "medium", function() {
            });
          e.stopPropagation();
        });
        $(document).click(function(event) { 
          if(!$(event.target).closest('.search-container').length) {
            if($('.search-container').is(":visible")) {
              $('.search-container').fadeOut(300);
            }
          }        
        });*/

        //Código para mostrar o formulário de newsletter

        $(".arrow-up-container").on('click',function(e){
          $( ".form-container" ).fadeIn( "medium", function() {
            });
          e.stopPropagation();
        });
        $(".header-container").on('click',function(e){
          $( ".form-container" ).fadeIn( "medium", function() {
            });
          e.stopPropagation();
        });
        $(document).click(function(event) { 
          if(!$(event.target).closest('.form-container').length) {
            if($('.form-container').is(":visible")) {
              $('.form-container').fadeOut(300);
            }
          }        
        });




        //Slider para mostrar as 1 artigo da categoria Novidades, 1 de cada vez
        $('.category-slider-container').slick({
          // normal options...
          infinite: true,
          dots: true,
          slidesToShow:1,
          slidesToScroll: 1,
          slickNext: false,
          arrows:true,
          //adaptiveHeight: true,
         // variableWidth: true,
          centerPadding: '0',
          draggable: true,
          swipe: true,
          centerMode:false,
          slide:'.category-slider',
          //fade: true,
          rows: 0,
          prevArrow: $(".arrow-left"),
          nextArrow: $(".arrow-right"),
          appendDots:$('.image-arrows-container'),


          // the magic
          /*responsive: [{

              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                infinite: true
              }

            }, {

              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                dots: true
              }

            }, {

              breakpoint: 300,
              settings: "unslick" // destroys slick

            }]*/
        });
        //Insere os dots depois da seta da esquerda para que fiquem entre as duas
       $(".slick-dots").insertAfter(".arrow-left");


        //Slider na Homepage - Secção 4, referente à coluna da esquerda
       $('.categories-names-slider').slick({
        // normal options...
        infinite: true,
        dots: false,
        slidesToShow:7,
        slidesToScroll: 1,
        arrows:false,
        //adaptiveHeight: true,
       // variableWidth: true,
       
        vertical: true,
        slide:'.categories-names-inner-slider',
        asNavFor: '.categories-images-names-slider',
        //fade: true,
        rows: 0,
        focusOnSelect: true,
        
       // appendDots:$('.image-arrows-container'),


        // the magic
        /*responsive: [{

            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              infinite: true
            }

          }, {

            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              dots: true
            }

          }, {

            breakpoint: 300,
            settings: "unslick" // destroys slick

          }]*/
      });


//Slider na Homepage - Secção 4, referente à coluna da direita

/**
 * Para que a terceira coluna fique escondida adicionou-se em css:
 * .categories-images-names-slider .slick-list {
    padding: 0 20% 0 0;
}
 */
$('.categories-images-names-slider').slick({
  // normal options...
  infinite: true,
  //dots: true,
  slidesToShow:2,
  slidesToScroll: 1,
  slickNext: false,
  //arrows:true,
  //adaptiveHeight: true,
 // variableWidth: true,
  centerPadding: '0',
  draggable: true,
  swipe: true,
  centerMode:false,
  //vertical: true,
  slide:'.categories-images-names-inner-slider',
  asNavFor: '.categories-names-slider',
  //fade: true,
  rows: 0,
  prevArrow: $(".arrow-left-categories"),
  nextArrow: $(".arrow-right-categories"),
 // appendDots:$('.image-arrows-container'),

  // the magic
  responsive: [ {

      breakpoint: 770,
      settings: {
        slidesToShow: 1,
      }

    }, {

      /*breakpoint: 300,
      settings: "unslick" // destroys slick*/

    }]
});






      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'single': {
      init: function() {
        // JavaScript to be fired on the single page
        $('.single-slider-container').slick({
          // normal options...
          infinite: true,
          dots: false,
          slidesToShow:1,
          slidesToScroll: 1,
          slickNext: false,
          arrows:true,
          //adaptiveHeight: true,
         // variableWidth: true,
          centerPadding: '0',
          draggable: true,
          swipe: true,
          centerMode:false,
          slide:'.single-slider',
          //fade: true,
          rows: 0,
          prevArrow: $(".arrow-left"),
          nextArrow: $(".arrow-right"),

          // the magic
          /*responsive: [{

              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                infinite: true
              }

            }, {

              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                dots: true
              }

            }, {

              breakpoint: 300,
              settings: "unslick" // destroys slick

            }]*/
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
